* {
  box-sizing: border-box;
  
  font-family: "Noto Sans KR", "Noto Sans CJK", "Nanum Gothic", "Malgun Gothic", sans-serif;

  -ms-overflow-style: none;
}

/* Custom scrollbar for non-iOS devices only */
@supports not (-webkit-touch-callout: none) {
  *::-webkit-scrollbar {
    width: 6px;
    height: 0;
  }

  *::-webkit-scrollbar-track {
    background-color: transparent;
  }

  *::-webkit-scrollbar-track-piece {
    background-color: transparent;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #c4c4c4aa;
    border-radius: 8px;
  }

  *::-webkit-scrollbar-button {
    width: 0;
    height: 0;

    background-color: transparent;
  }

  *::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  *::-webkit-resizer {
    background-color: transparent;
  }
}

html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: overlay;

  font-family: "Noto Sans KR", "Noto Sans CJK", "Nanum Gothic", "Malgun Gothic", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #fafafa;

  scrollbar-width: none;
  -ms-overflow-style: none;
}

a {
  color: inherit;
  text-decoration: none;
}

textarea {
  font-family: "Noto Sans KR", "Noto Sans CJK", "Nanum Gothic", "Malgun Gothic", sans-serif !important;
}

button {
  font-family: "Noto Sans KR", "Noto Sans CJK", "Nanum Gothic", "Malgun Gothic", sans-serif !important;
}

.first-view-modal .MuiBackdrop-root {
  background: none !important;
}

